import {
    VIDEO_CALL_SET_CONFIG
} from '../constants/ActionTypes';

const estadoInicial = {
    sessionId: window.sagicc_videocall_config ? window.sagicc_videocall_config.session_id : null,
    role: window.sagicc_videocall_config ? window.sagicc_videocall_config.role_type : null,
    backUrl: window.sagicc_videocall_config ? window.sagicc_videocall_config.back_url : null,
    sessionStatus: false,
    config: {
        sessionToken: null,
        sessionName: null,
        userName: null,
        sessionPasscode: null
    }
}

const videocall = (state = estadoInicial, action) => {
    switch (action.type) {
        case VIDEO_CALL_SET_CONFIG:
            return {
                ...state,
            }
        default:
            return state;
    }
}

export default videocall;
