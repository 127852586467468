import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Main from './components/Main';
import OmniWidget from './components/Omniwidget/OmniWidget';
import VideoCall from './components/VideoCall/VideoCall';

function App() {
  const { widgetToken, videoCallToken } = useSelector(state => ({
    widgetToken: state.chat.widgetToken,
    videoCallToken: state.videoCall.sessionId
  }), shallowEqual);

  if (videoCallToken && videoCallToken !== '') {
    return <VideoCall />
  }

  if (widgetToken && widgetToken !== '') {
    return <OmniWidget />
  }

  return (
    <Main />
  );
}

export default App;
