var axios = require('axios');

let backUrl = null;

if (window.sagicc_chat_config && window.sagicc_chat_config.back_url) {
    backUrl = window.sagicc_chat_config.back_url;
} else if (window.sagicc_omni_widget_config && window.sagicc_omni_widget_config.back_url) {
    backUrl = window.sagicc_omni_widget_config.back_url;
} else if (window.sagicc_videocall_config && window.sagicc_videocall_config.back_url) {
    backUrl = window.sagicc_videocall_config.back_url;
}

var axiosInstance = axios.create({
    baseURL: backUrl + '/api/v2',
    headers: { 'X-Locale': localStorage.getItem('sagicc_chat_default_language') || 'en' },
});

module.exports = axiosInstance;
