/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import styles from './styles';

import i18n from '../../i18n';
import axiosInstance from '../../constants/Api';
import { handleNotificacion } from '../../actions/chat';

// Material UI
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';// Material UI

function VideoCall({ classes }) {

    const { sessionId, role } = useSelector(state => ({
        sessionId: state.videoCall.sessionId,
        role: state.videoCall.role
    }), shallowEqual);

    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    let sessionContainer = null;
    const joinSession = (configVideoCall) => {
        const config = {
            ...configVideoCall,
            features: ['video', 'audio', 'settings', 'users', 'share'],
            options: { init: {}, audio: {}, video: { virtualBackground: true }, share: {} },
            featuresOptions: {
                virtualBackground: {
                    enable: true,
                    virtualBackgrounds: [
                        {
                            url: "https://images.unsplash.com/photo-1715490187538-30a365fa05bd?q=80&w=1945&auto=format&fit=crop",
                        }
                    ]
                }
            }
        };

        sessionContainer = document.getElementById("sessionContainer");

        uitoolkit.joinSession(sessionContainer, config);
        uitoolkit.onSessionClosed(sessionClosed);
    }

    const sessionClosed = () => {
        uitoolkit.closeSession(sessionContainer);
    };

    useEffect(() => {
        getVideoCallConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVideoCallConfig = () => {
        setLoading(true);
        axiosInstance.get('/canal-zoom/session-config', {
            params: {
                hashedId: sessionId,
                role: role,
                domain: window.location.hostname
            }
        }).then(function (response) {
            if (response.data.statusResult === true && response.data.status === 'OK') {

                setLoading(false);
                // DATA
                const result = response.data.result;

                joinSession({
                    videoSDKJWT: result.config.token,
                    sessionName: result.config.key,
                    sessionPasscode: result.config.passcode,
                    userName: 'Sagicc'
                });
            }
        }).catch(function (error) {
            dispatch(handleNotificacion(true, i18n.t('error')));
            // setLoading(false);
            console.log(error);
        });

    }

    return (
        <>
            {loading ? (
                <div className={classes.circularProgress}>
                    <CircularProgress size={90} thickness={1} color="primary" />
                    <Typography variant="subtitle1" className={classes.text} gutterBottom>
                        Authenticating...
                    </Typography>
                </div>
            ) : (
                <div id="sessionContainer" className={classes.sessionContainer}></div>
            )}
        </>
    );
}

export default withStyles(styles)(VideoCall);