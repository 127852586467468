const styles = (theme) => ({
    sessionContainer: {
        display: 'flex',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#282c34',
        justifyContent: 'center'
    },
    circularProgress: {
        position: 'fixed',
        top: 'calc(50% - 45px)',
        left: '50%',
        textAlign: 'center'
    },
    text: {
        marginTop: 8,
        opacity: 0.75
    }
});
  
export default styles;
  